@import "src/styles/styles";

.toolbar {
    width: 100%;
    background-color: $NAVY;
    z-index: 1200;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
    padding: 17px 0 17px;

    .inner {
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 18px;

        max-width: $CONTAINER_WIDTH;

        .logo {
            padding-top: 9px;
            a {
                background-color: transparent;
            }
            img {
                width: 115px;
            }
        }

        .menu {
            flex: 1;
            align-items: center;
            display: flex;
            justify-content: flex-end;

            > :not(:last-child) {
                margin-right: 9px;
                text-decoration: none;
            }
        }
    }
}
.nav_link {
    color: $WHITE;
    opacity: 1;
    background-color: transparent !important;
    border: none !important;
    font-size: 14px;
    min-height: 34px;
    text-transform: none !important;
    text-decoration: none;
    font-family: "Poppins", serif;
    font-weight: 500;
    padding: 9px 18px !important;
    letter-spacing: 0;

    img {
        margin-left: 9px;
        width: 13px;
        opacity: 0.5;
    }
}

.popover_menu {
    display: flex;
    flex-direction: column;
    padding: 0 18px 18px 9px;

    > div {
        background-color: $NAVY;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
    }

    button,
    a {
        justify-content: unset;
        border-radius: 0;
    }
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
    .toolbar {
        padding: 14px 0 13px;

        .inner {
            .logo {
                img {
                    max-width: 92px;
                }
            }
            .search_input {
                display: none !important;
            }
        }
    }
}
