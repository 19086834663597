@import "src/styles/styles";

.noPadding {
    padding: 0;
}

.padding {
    padding-top: 9px;
    .menu_dropdown_container {
        position: relative;
        z-index: 1;
        .menu_dropdown {
            top: -27px;
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 100;
            width: 100%;
            background-color: white;
            cursor: pointer;
            .menu_item {
                padding: 8px 12px;
                border-width: 1px;
                border-left-style: solid;
                border-right-style: solid;
                border-color: #f1f1f2;
                z-index: 1000;
                width: 100%;
            }
            :hover {
                background-color: #f1f1f2;
            }
            :last-child {
                border-bottom-style: solid;
                border-color: #f1f1f2;
            }
        }
    }
}

.input {
    line-height: 1 !important;
    width: 100%;
    max-width: 414px;

    //:global(.Mui-disabled) {
    //    cursor: not-allowed;
    //}

    label {
        color: $BLACK;
        font-size: 13px;
        transform: none;
        margin-bottom: 2px;
        font-family: "Poppins", sans-serif;
        font-weight: bold;
    }

    .input_root {
        background-color: $WHITE;

        margin-bottom: 12px;
        min-height: 38px;
        border-radius: 5px;
        border: solid $PLUM_GREY 1px;
        padding: 4px 12px 4px;
        transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: "Poppins", sans-serif;
        font-size: 16px;

        &:focus {
            outline: none;
            box-shadow: $LIGHT_GREY 0 0 0 0.05rem;
        }

        @media (min-width: 240px) {
            min-width: 204px;
        }

        .adornment_root,
        :global(.MuiInputAdornment-root) {
            > svg {
                fill: rgba(0, 0, 0, 0.87);
            }

            > p {
                color: rgba(0, 0, 0, 0.87);
            }
        }
    }

    input::-webkit-input-placeholder {
        line-height: normal !important;
    }

    .select_root {
        min-width: unset;
    }
}

.label_tooltip_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
}

.multiline {
    .input_root {
        textarea {
            min-height: 250px;
        }
    }
}

.fullWidth {
    max-width: unset;
}

.select_menu {
    border-radius: 4px;
}

.info_popover {
    padding: 18px;
}

.formik_input {
    width: 100%;
    border-radius: 5px;
    border: solid #dfd7dc 1px;
    padding: 4px 12px 4px;
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Poppins", sans-serif;
    font-size: 16px;

    &:focus {
        outline: none;
        box-shadow: $LIGHT_GREY 0 0 0 0.05rem;
    }
}

.disabled {
    .input_root {
        background-color: transparent;
        border: 1px solid transparent;
        color: $GREY_DARK;
    }
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
    .label_tooltip_container {
        display: none;
    }
}
