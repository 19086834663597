@import "src/styles/styles";

.icon_btn {
    cursor: pointer;
}
.controls {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
}
.back {
    cursor: pointer;
    color: $TURQUOISE;
    font-weight: bold;
}
.select {
    margin-left: 9px;
    max-width: 450px;
}
