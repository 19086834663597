.edit_category_dialog {
  .category_container {
    .subsub_container {
      width: 100%;
      text-align: left;
    }

  }
}

.edit_name_wrapper {
  display: flex;
  align-items: flex-end;

  button {
    margin-left: 9px;
    max-height: 48px;
  }
}