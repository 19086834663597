@import "src/styles/styles";

.form {
    hr {
        border: 1px solid $LIGHT_GREY;
        margin: 18px 0px 36px;
    }

    .full_input {
        position: relative;
        max-width: unset;
        width: 100%;
        margin-bottom: 27px;
        > div {
            width: 100%;
            max-width: unset;
        }
    }

    .logo_image {
        > div {
            background-size: contain;
            border-radius: 0px;
        }
    }

    .half_input {
        max-width: unset;
        padding-top: 0px;
        margin-bottom: 20px;
        width: 49%;
        display: inline-block;
        margin-right: 5px;
        vertical-align: top;
        > div {
            width: 100%;
            max-width: unset;
        }
    }
    .half_input_padding {
        padding-top: 18px;
    }
    .form_section {
        margin: 18px 0px 36px;
    }

    .third_input_small {
        vertical-align: top;
        width: 20%;
        margin-bottom: 0px !important;
        display: inline-block;
    }
    .checkbox_container {
        margin-bottom: 36px;

        span {
            font-family: "Poppins", sans-serif;
        }

        label {
            width: 49%;
        }
    }
    label {
        color: #444444;
        font-size: 13px;
        transform: none;
        margin-bottom: 2px;
        font-family: "Poppins", sans-serif;
        font-weight: bold;
    }
}

.category_container {
    box-shadow: none;
    margin-bottom: 0px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .category_item {
        box-shadow: none;

        .category_label {
            label {
                margin-right: 0px;
            }
            span {
                font-weight: bold;
            }
        }
    }
}
.remove_button {
    color: $RED;
}
.no_affiliations {
    margin: 9px 0px;
    font-weight: bold;
    font-size: 13px;
    color: $PURPLE;
}
.plain_subindustry_row {
    //border: 1px solid black;
    margin-bottom: 0px;
    padding: 12px 6px;
    box-shadow: none;
}
.subsub_container {
    > label {
        display: block;
    }
}
.footer_btns {
    button {
        margin-right: 18px;
    }
}
.induct_container {
    background-color: $GREY_MID;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 27px 0;
    margin-bottom: 27px;
    border-radius: 6px;
}
.induct {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .pickers {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .date_picker {
            flex: 1;
            margin-bottom: 0px;
            > div {
                margin-bottom: 0px;
            }
        }
        .selector {
            flex: 1;
            margin-bottom: 0px;
            > div {
                padding: 9px;
            }
        }
        .spacer {
            width: 9px;
        }
    }
}
.induct_active {
    background-color: $TURQUOISE_GREY;
}
.induct_editing {
    background-color: $GREY_LIGHT;
}
.induct_helper {
    align-self: center;
    font-weight: bold;
    font-size: 12px;
    color: $RED;
}
.back {
    cursor: pointer;
    color: $TURQUOISE;
    font-weight: bold;
}
