@use "src/styles/styles";

.container {
    text-align: left;
    .input {
        margin: 27px auto 27px 0;
        width: 36%;
    }
    .description {
        margin-top: 18px;
    }

    .block {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        > * {
            flex-basis: 33%;
        }
    }
    .footer {
        margin-top: 36px;
        display: flex;
        justify-content: space-between;
    }
}
.dialog {
    max-width: 75vw !important;
}
