@import "src/styles/styles";

.btns {
    color: $BLACK;
    opacity: 1;
    background-color: transparent !important;
    border: none !important;
    font-size: 14px;
    min-height: 34px;
    text-transform: none !important;
    text-decoration: none;
    font-family: "Poppins", serif;
    font-weight: 500;
    padding: 0px 10px !important;
    letter-spacing: 0;
}
.red {
    color: $ERROR;
}

.popover_options {
    display: flex;
    flex-direction: column;
    > div {
        background-color: $WHITE;
        box-shadow: none;
        border: 1px solid $GREY_BORDER;
    }
    button,
    a {
        justify-content: unset;
        border-radius: 0;
    }
}
