.dialog{
  min-width: 1200px;
  display: flex;
  flex-direction: column;
.dialog_container{


  }
  .formik_input{
    width: 100%;
    margin: 0 10px;
  }
}