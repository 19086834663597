@import "src/styles/styles";

.bar {
    background-color: $PURPLE;
    color: $WHITE;
    font-size: 13px;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 32px;
    .dismiss {
        text-decoration: underline;
        cursor: pointer;
    }
}
.grey {
    background-color: #cdcdcd;
}
