/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 6/11/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

.error {
    color: #f44336;
    font-size: 1rem;
    padding: 9px 4px 18px;
}
