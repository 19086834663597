@import "src/styles/styles";

.hide_input {
}

.full_input {
    max-width: unset;
    width: 100%;
    margin-bottom: 27px;
    > div {
        width: 100%;
        max-width: unset;
        label {
            color: $BLACK;
            font-size: 13px;
            transform: none;
            margin-bottom: 2px;
            font-family: "Poppins", sans-serif;
            font-weight: bold;
        }
    }
}

.dialog_content {
    text-align: left;

    .dialog_btns {
        text-align: left;
    }

    .dialog_btns > button:first-child {
        margin-right: 18px;
    }
}
