@import "src/styles/styles";

.radios {
    display: flex;
    justify-content: space-between;
}
.label {
    font-size: 12px;
    font-weight: bold;
    color: $GREY_DARK;
}
