@import "src/styles/styles";

.owner_form {
    min-height: unset !important;
}

.form {
    min-height: 60vh;

    hr {
        border: 1px solid $LIGHT_GREY;
        margin: 18px 0px 36px;
    }

    .full_input {
        position: relative;
        max-width: unset;
        width: 100%;
        margin-bottom: 27px;
        > div {
            width: 100%;
            max-width: unset;
        }
    }

    .logo_image {
        > div {
            background-size: contain;
            border-radius: 0px;
        }
    }

    .half_input {
        max-width: unset;
        padding-top: 0px;
        margin-bottom: 27px;
        width: 49%;
        display: inline-block;
        vertical-align: top;
        > div {
            width: 100%;
            max-width: unset;
        }
    }
    .form_section {
        margin: 18px 0px 36px;
    }
    .full_text_input {
        margin-bottom: 45px;

        > div {
            > div {
                textarea {
                    outline: none;
                    max-width: 100%;
                    padding: 9px;
                    box-sizing: border-box;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    border-radius: 5px;
                    min-height: 165px;
                    width: 100%;
                }
            }
        }
    }
    .health_safety_container {
        display: block;
        label {
            width: 100%;
        }
    }

    .first {
        margin-right: 2%;
    }
    .paragraph_input {
        margin-bottom: 45px;
        outline: none;
        max-width: 100%;
        padding: 9px;
        box-sizing: border-box;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        border: 1px solid #cdcdcd;
        border-radius: 5px;
        min-height: 165px;
        width: 100%;
    }

    .third_input {
        vertical-align: top;
        width: 38%;
        margin-bottom: 0px !important;
        display: inline-block;
    }

    .add_button {
        display: block;
        margin-bottom: 27px;
    }
    .add_button_owner {
        display: block;
        margin-bottom: 9px;
    }

    .third_input_small {
        vertical-align: top;
        width: 20%;
        margin-bottom: 0px !important;
        display: inline-block;
    }
    .checkbox_container {
        margin-bottom: 36px;

        span {
            font-family: "Poppins", sans-serif;
        }

        label {
            width: 49%;
        }
    }
    label {
        color: $BLACK;
        font-size: 13px;
        transform: none;
        margin-bottom: 2px;
        font-family: "Poppins", sans-serif;
        font-weight: bold;
    }
}

.category_container {
    box-shadow: none;
    margin-bottom: 0px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .category_item {
        box-shadow: none;

        .category_label {
            label {
                margin-right: 0px;
            }
            span {
                font-weight: bold;
            }
        }
    }
}
.remove_cat_button {
    text-align: right;
    span {
        color: red;
    }
}
.remove_button {
    color: $RED;
}
.error {
    color: $ERROR;
    font-size: 0.75rem;
    font-weight: bold;
}
.no_affiliations {
    margin: 9px 0px;
    font-weight: bold;
    font-size: 13px;
    color: $PURPLE;
}
.plain_subindustry_row {
    //border: 1px solid black;
    margin-bottom: 0px;
    padding: 12px 6px;
    box-shadow: none;
}
.subsub_container {
    > label {
        display: block;
    }
}
.footer_btns {
    button {
        margin-right: 18px;
    }
}
