@import "src/styles/styles";

.overview {
    width: 100%;
    .subtitle {
        display: flex;
        align-items: center;
        > span {
            font-weight: bold;
            font-size: larger;
        }
        > :nth-child(2) {
            margin-left: 20px;
        }
    }
    .subsubtitle {
        margin-top: 9px;
        margin-bottom: 18px;
    }
}
