@import "src/styles/styles";

.uploader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > span {
        font-size: 14px;
        font-weight: bold;
        color: $GREY_DARK;
    }
}
.link {
    padding: 0 0 9px 9px;
    text-decoration: underline;
    cursor: pointer;
}
.disabled {
    color: $GREY;
    cursor: text;
}
