@import "src/styles/styles";

.login {
    background-color: $BACKGROUND_OPACITY;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: $DESKTOP_BREAKPOINT;
        padding: 19px;

        .logo {
            margin-bottom: 54px;
            max-width: 220px;
            width: 100%;
        }

        .form {
            max-width: 600px;
            width: 100%;
            padding-top: 36px;
            display: flex;
            flex-direction: column;

            > * {
                margin-bottom: 18px;
            }

            > Button {
                width: 100%;
                margin-top: 32px;
            }
        }

        .card {
            max-width: 567px;
            min-width: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 18px;
        }
    }
}
