@import "src/styles/styles";

.billing {
    width: 100%;
    max-width: 80%;
    padding-left: 20px;

    h5 {
        border-bottom: 1px solid $LIGHT_GREY;
        padding-bottom: 3px;
        margin-bottom: 29px;
    }
}

.items {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .item {
        display: flex;
        flex-direction: column;

        span {
            display: block;
        }

        .label {
            font-weight: bold;
            font-size: 13px;
            margin-bottom: 3px;
        }
    }
}
.clickable_item {
    color: $TURQUOISE;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        color: $PURPLE;
        opacity: 0.6;
        transition: ease-in-out 125ms;
    }
}
.red {
    color: $RED;
    &:hover {
        color: $RED_LIGHT;
    }
}
.disabled {
    color: $GREY_LIGHT;
    cursor: not-allowed;
}
.form_section {
    z-index: 0;
    margin: 18px 0px 36px;
}
.half_input {
    max-width: unset;
    padding-top: 0px;
    margin-bottom: 27px;
    width: 49%;
    display: inline-block;
    vertical-align: top;
    > div {
        width: 100%;
        max-width: unset;
    }
}
.first {
    margin-right: 2%;
}
.section {
    margin-top: 81px;
}
.download_link {
    font-weight: bold;
    color: $TURQUOISE;
    font-size: 14px;
}
.billing_date_form {
    .button {
        margin-top: 27px;
        width: 100%;
    }
    .date_picker {
        min-width: unset;
        flex: 1;
        margin-bottom: 0px;
    }
}
.confirm_dialog {
    display: flex;
    flex-direction: column;
    .button {
        margin-top: 27px;
        width: 100%;
    }
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
    .billing {
        max-width: 100%;
        padding-left: 0px;
        .items {
            display: block;

            .item {
                width: 100%;
                margin-bottom: 18px;
            }
        }
    }
    .view_plans {
        .plans {
            display: block;
        }
    }
}
