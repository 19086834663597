@import "src/styles/styles";

.padding {
    padding-top: 9px;
    margin-bottom: 12px;

    label {
        color: $BLACK;
        font-size: 13px;
        transform: none;
        margin-bottom: 4px;
        font-family: "Poppins", sans-serif;
        font-weight: bold;
    }

    > .select {
        border: solid #dfd7dc 1px;
        background: $WHITE
            url(../../../resources/images/svg/arrow-down-dark.svg) no-repeat;
        background-position: center right;
        background-origin: content-box;
        min-height: 38px;
        max-height: 46px;
        border-radius: 5px;
        padding: 4px 12px 4px 4px;
        box-sizing: border-box;
        transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: "Poppins", sans-serif;
        font-size: 16px;

        &:focus {
            outline: none;
            box-shadow: $LIGHT_GREY 0 0 0 0.05rem;
            background-image: none;
            -webkit-background-image: none;
            -moz-background-image: none;
        }

        > svg {
            display: none;
        }

        > div {
            background-image: none;
            -webkit-background-image: none;
            -moz-background-image: none;
            background-color: transparent;
        }
    }
}

.noPadding {
    padding: 0px;
}

.label_tooltip_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
}

.select:before {
    display: none;
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
    .label_tooltip_container {
        img {
            display: none;
        }
    }
}
