@import "src/styles/styles";

.ownership_type {
    margin: 18px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .ownership_text {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        font-style: italic;
        > img {
            margin-right: 9px;
        }
    }
}
.dialog_content {
    justify-content: flex-start;
    text-align: left;

    .dialog_btns {
        text-align: left;
    }

    .dialog_btns > button:first-child {
        margin-right: 18px;
    }
}
