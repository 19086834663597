@import "src/styles/styles";

.event {
    max-width: 414px;
    width: 100%;
    // padding: 18px 0;

    > span {
        font-size: 14px;
        font-weight: bold;
        color: $GREY_DARK;
    }

    .event_list {
        width: 100%;
        columns: 2;
    }
}
