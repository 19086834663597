@import "src/styles/styles";

.spinner {
    position: relative;
    display: flex;
    height: 27px;
    width: 27px;
    animation: SpinnerAnimationShow 0.25s normal ease,
        SpinnerAnimationRotation 0.7s linear infinite;
    transition-property: opacity, transform;
    transition-timing-function: ease;
    transform-origin: 50% 50%;
}

.white {
    .spinner_ellipse {
        stroke: white;
    }
}

.small {
    height: 18px;
    width: 18px;
}

.large {
    height: 54px;
    width: 54px;
}

.spinner_ellipse {
    fill: transparent;
    stroke: #8792a2;
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-dasharray: 60;
    stroke-dashoffset: 20;
}

@keyframes SpinnerAnimationShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes SpinnerAnimationRotation {
    100% {
        transform: rotate(360deg);
    }
}

.container {
    display: flex;
    padding: 9px;
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background-color: $BACKGROUND_OPACITY;
    z-index: 100;
}

.center {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.vCenter {
    height: 100%;
    align-items: center;
}

.hCenter {
    width: 100%;
    justify-content: center;
}
