@import "src/styles/styles";

.container {
    width: 100%;
    .banner {
        text-align: center;
        padding-top: 9px;
        padding-bottom: 9px;
        background-color: $LIGHT_GREY;
        font-weight: bold;
        border-radius: 9px;
        .bannerTitle {
            font-size: 36px;
        }
    }
    .subtitle {
        font-size: 18px;
        font-weight: bold;
        margin-top: 27px;
        margin-bottom: 9px;
    }
    .description {
        margin-bottom: 27px;
    }
}
.link {
    cursor: pointer;
    text-decoration: underline;
}
