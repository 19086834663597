/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Translate Digital
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

@import "colours";
@import "fonts";


//FROM DIRECTORY

$TABLET_BREAKPOINT: 766px;
$DESKTOP_BREAKPOINT: 990px;

$CONTAINER_WIDTH: 1367px;
$INNER_CONTAINER_WIDTH: 960px;
$CONTENT_WIDTH: 1126px;

$PADDING: 9px;
$BORDER_RADIUS: 9px;

@media (min-width: $TABLET_BREAKPOINT) {
  $PADDING: 18px;
}



//FROM CHRIS

.form_row {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 414px;
  padding: 18px 0;

  > * {
    flex: 1;

    > div {
      min-width: unset!important;
    }
  }

  > :nth-child(1n) {
    margin-right: 9px;
  }

  > :only-child, > :last-child {
    margin: 0;
  }

  .or {
    flex: unset;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
  }

  .form_row_button {
    flex: unset;
    margin-top: 20px;
    //color: grey;
  }

  .form_row_spacer {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
}

.form_row_full {
  @extend .form_row;
  max-width: unset;
}
