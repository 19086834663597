@import "src/styles/styles";

.owner_form {
    min-height: unset !important;
}
.form {
    min-height: 60vh;
    .regional_manager_section {
        margin-bottom: 20px;
        .add_rm_button {
            bottom: 12px;
        }
    }
    hr {
        border: 1px solid $LIGHT_GREY;
        margin: 18px 0px 36px;
    }

    .full_input {
        position: relative;
        max-width: unset;
        width: 100%;
        margin-bottom: 27px;
        > div {
            width: 100%;
            max-width: unset;
        }
    }
    .address_input {
    }
    .logo_image {
        > div {
            background-size: contain;
            border-radius: 0px;
        }
    }

    .half_input {
        max-width: unset;
        padding-top: 0px;
        margin-bottom: 27px;
        width: 49%;
        display: inline-block;
        vertical-align: top;
        > div {
            width: 100%;
            max-width: unset;
        }
    }
    .form_section {
        margin: 18px 0px 36px;
    }
    .full_text_input {
        margin-bottom: 45px;

        > div {
            > div {
                textarea {
                    outline: none;
                    max-width: 100%;
                    padding: 9px;
                    box-sizing: border-box;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    border-radius: 5px;
                    min-height: 165px;
                    width: 100%;
                }
            }
        }
    }
    .health_safety_container {
        display: block;
        label {
            width: 100%;
        }
    }

    .first {
        margin-right: 2%;
    }
    .paragraph_input {
        margin-bottom: 45px;
        outline: none;
        max-width: 100%;
        padding: 9px;
        box-sizing: border-box;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        border: 1px solid #cdcdcd;
        border-radius: 5px;
        min-height: 165px;
        width: 100%;
    }

    .third_input {
        vertical-align: top;
        width: 38%;
        margin-bottom: 0px !important;
        display: inline-block;
    }

    .add_button {
        display: block;
        margin-bottom: 27px;
    }
    .add_button_owner {
        display: block;
        margin-bottom: 9px;
    }

    .third_input_small {
        vertical-align: top;
        width: 20%;
        margin-bottom: 0px !important;
        display: inline-block;
    }
    .checkbox_container {
        margin-bottom: 36px;

        span {
            font-family: "Poppins", sans-serif;
        }

        label {
            width: 49%;
        }
    }
    label {
        color: $BLACK;
        font-size: 13px;
        transform: none;
        margin-bottom: 2px;
        font-family: "Poppins", sans-serif;
        font-weight: bold;
    }
}

.category_container {
    box-shadow: none;
    margin-bottom: 0px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .category_item {
        box-shadow: none;

        .category_label {
            label {
                margin-right: 0px;
            }
            span {
                font-weight: bold;
            }
        }
    }
}
.remove_button {
    color: $RED;
}
.no_affiliations {
    margin: 9px 0px;
    font-weight: bold;
    font-size: 13px;
    color: $PURPLE;
}
.plain_subindustry_row {
    //border: 1px solid black;
    margin-bottom: 0px;
    padding: 12px 6px;
    box-shadow: none;
}
.subsub_container {
    > label {
        display: block;
    }
}
.footer_btns {
    button {
        margin-right: 18px;
    }
}

.induct_container {
    background-color: $GREY_MID;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 27px 9px;
    margin-bottom: 27px;
    border-radius: 6px;
}
.induct {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .pickers {
        margin-left: 9px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .selector {
            flex: 1;
            max-width: 270px;
            margin-bottom: 0px;
            > div {
                padding: 3px 9px;
            }
        }
        .induct_by_at {
            margin-left: 9px;
            flex: 2;
            display: flex;
            flex-direction: row;
            .date_picker {
                min-width: unset;
                flex: 1;
                margin-bottom: 0px;
            }
            .selector_by {
                flex: 1;
                margin-top: -9px;
                margin-left: 9px;
                max-width: 360px;
            }
        }
    }
}
.induct_active {
    background-color: $TURQUOISE_GREY;
}
.induct_editing {
    background-color: $GREY_LIGHT;
}
.induct_helper {
    align-self: center;
    font-weight: bold;
    font-size: 12px;
    color: $RED;
}

.account_status {
    margin-bottom: 27px;
}
