@import "src/styles/styles";

.dialog_container {
    .dialog {
        max-width: 396px;
        border-radius: 0 0 10px 10px;
        border-top: 9px solid $PURPLE;
        margin: 0;
        width: 100%;

        .header {
            padding: 32px 18px 18px;
            span {
                font-size: 18px;
                font-weight: bold;
            }
            div {
                border-bottom: 1px solid $BLACK;
            }
        }

        .content {
            padding: 9px 18px;
            text-align: center;

            > :not(:last-child) {
                margin-bottom: 9px;
            }
        }

        .buttons {
            justify-content: space-between;
            padding: 9px 18px;
            //box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.09);
            //border-top: 1px solid #b4c3ca;

            > :not(:first-child) {
                margin-left: 9px;
            }
        }

        .buttonsRight {
            display: flex;
            flex: 1;
            justify-content: flex-end;

            > * {
                width: unset;
            }

            > :not(:first-child) {
                margin-left: 9px;
            }
        }
    }
}
