@import "src/styles/styles";

.top_tool_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.top_pagination {
    z-index: 10;
    right: 0;
    padding: 18px;
}
.bottom_pagination {
    z-index: 10;
    padding: 18px;
    text-align: right;
}
.pagination_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .button {
        box-shadow: none;
        max-width: 30px;
        min-width: 30px;
        max-height: 30px;
        min-height: 30px;
    }
}
.select_renderer {
    padding: 0 5px;
}
