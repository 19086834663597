@import "src/styles/styles";

.secondary {
    width: 36px;
}
.menu {
    background: #f9f9f9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 9px 18px;
}
