@import "src/styles/styles";

.tooltip {
    background-color: $BLACK;
    color: $WHITE;
    border-radius: 6px;
    max-width: 360px;
    padding: 9px 18px;
    overflow-wrap: break-word;
}
.tip_link {
    color: $WHITE;
}
