/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 29/06/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

//AMOTAI COLOURS

$BLACK: #231F20;
$NAVY: #00354E;
$TURQUOISE: #008997;
$PLUM: #4B1642;
$PURPLE: #832676;
$WHITE: #FFFFFF;

$BLUE_GREY: #E4E9EC;
$TURQUOISE_GREY: #DBECED;
$PURPLE_GREY: #ECE7EB;
$PLUM_GREY: #DFD7DC;
$LIGHT_GREY: #F1F1F2;

$BACKGROUND_OPACITY: #F5F5F5;


//OTHER COLOURS

$SUCCESS: #77bb43;
$ERROR: #ff333d;
$WARNING: #f18024;
$ORANGE: #f18024;

$BACKGROUND: #f5f7f9;
$BACKGROUND_OPACITY: #f5f7f995;

$PRIMARY: #1a73e8;
$PRIMARY_DARK: #2262C6;
$PRIMARY_LIGHT: #8ab4f8;
$PRIMARY_TEXT: #fff;

$SECONDARY: #f18024;
$SECONDARY_DARK: rgb(195, 116, 25);
$SECONDARY_LIGHT: rgb(243, 153, 79);
$SECONDARY_TEXT: rgba(0, 0, 0, 0.87);

$RED: #ea2831;
$RED_DARK: rgb(203, 7, 48);
$RED_LIGHT: rgb(137, 16, 42);

$GREY_BORDER: #b4c3ca;
$GREY_LIGHT: #EEEEEE;
$GREY_DARK: #444444;
$GREY: #929292;
$GREY_MID: #CDD2D5;

$TEXT: #4F566B;
$DISABLED_TEXT: rgba(0, 0, 0, 0.38);

$BOX_SHADOW: 0 .125rem .625rem rgba(90, 97, 105, .12);

$PRIMARY_COLOR: #212121;
$FOREGROUND_COLOR: #fff;
$SEPARATOR_COLOR: #d7d7d7;


