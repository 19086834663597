@import "src/styles/styles";

.container {
    width: 100%;
    .subtitle {
        font-size: 18px;
        font-weight: bold;
        margin-top: 27px;
        margin-bottom: 9px;
    }
    .description {
        margin-bottom: 27px;
    }
}
