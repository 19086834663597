@import "src/styles/styles";

.plan_form {
    padding: 27px 18px;
    text-align: left;
    .row {
        display: flex;
        justify-content: space-between;
    }
    .full_input {
        max-width: unset;
        width: 100%;
        margin-bottom: 27px;
        > div {
            width: 100%;
            max-width: unset;
            label {
                color: $BLACK;
                font-size: 13px;
                transform: none;
                margin-bottom: 2px;
                font-family: "Poppins", sans-serif;
                font-weight: bold;
            }
        }
    }
    .footer {
        margin-top: 36px;
        display: flex;
        justify-content: flex-start;
        div {
            min-width: 9px;
        }
    }
}
.error_block {
    font-size: 9px;
    font-weight: bold;
    color: red;
}
