@import "src/styles/styles";

.events {
    .single_link {
        text-decoration: underline;
        cursor: pointer;
    }
    .links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        > span {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .dialog {
        border-radius: 0;
    }
}
