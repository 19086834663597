@import "src/styles/styles";

.snackbar {
    margin-bottom: 18px;
    position: relative;
    top: unset;
    left: unset;
    right: unset;

    .snackbar_content {
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        border-radius: 9px;
        //padding: 18px;
        display: flex;
        flex-direction: row;
        flex-wrap: unset;

        svg {
            color: white;
        }

        .snackbar_content_message {
            flex: 1;
            display: flex;
            align-items: center;

            > span {
                margin-left: 9px;
            }
        }
    }

    .success {
        background-color: $SUCCESS;
    }

    .error {
        background-color: $ERROR;
    }

    .warning {
        background-color: $WARNING;
    }
}
