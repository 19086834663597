@import "src/styles/styles";

.regions {
    margin-bottom: 36px;
    width: 100%;
    padding: 18px 0;

    > span {
        font-size: 14px;
        font-weight: bold;
        color: $GREY_DARK;
    }

    .regions_list {
        width: 100%;
    }
}
