@import "src/styles/styles";

.form {
    max-width: 414px;
}

.icon_btn {
    cursor: pointer;
}

.icon_btn_disabled {
    cursor: not-allowed;
    color: $GREY;
}
