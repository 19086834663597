@import "src/styles/styles";

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 27px;
    border-bottom: 1px solid $LIGHT_GREY;
    padding: 0 0 9px;

    .content {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;

        > h5 {
            margin: 0 0 6px;
        }

        .subtitle {
            font-size: 13px;
            color: $NAVY;
        }
    }
    .action {
        display: flex;
        flex-direction: row;

        > :nth-child(1n) {
            margin-left: 9px;
        }
    }

    button {
        max-height: 48px;
    }
}
