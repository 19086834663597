@import "src/styles/styles";

.file_upload {
    .avatar_image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        margin-bottom: 27px;
    }
    .label {
        margin: 18px 0px 18px;
    }
    .error {
        color: $ERROR;
        font-size: 0.75rem;
    }
}
