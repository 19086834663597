@import "src/styles/styles";

.side_bar_container {
    width: 100%;
    display: flex;
    flex-direction: row;

    .side_bar {
        max-width: 250px;
        min-width: 250px;
        width: 100%;
        display: flex;
        flex-direction: column;

        button,
        a {
            border-radius: 0px;
            width: 100%;
            text-decoration: none;
            text-align: left !important;
            background-color: transparent !important;
            border: none;
            text-transform: none;
            color: $BLACK;
            border-bottom: 1px solid $LIGHT_GREY;
            box-shadow: none;

            span {
                text-align: left;
                justify-content: unset;
            }
        }
        button:first-child,
        a:first-child {
            border-top: 1px solid $LIGHT_GREY;
        }
    }

    .content {
        flex: 1;
        padding: 18px;
    }
}
