@import "src/styles/styles.scss";

.container {
    display: flex;
    justify-content: flex-start;
}

.pill {
    font-size: 15px;
    padding: 3px 18px;
    border-radius: 15px;
    background-color: $GREY_LIGHT;
    margin-right: 9px;
}
.pending_review {
    background-color: $TURQUOISE_GREY;
}
.active {
    background-color: $TURQUOISE;
    color: $WHITE;
}
.on_hold {
    color: $WHITE;
    background-color: $NAVY;
}
.declined {
    background-color: $PURPLE;
    color: $WHITE;
}
.deleted {
    background-color: $GREY_MID;
}
