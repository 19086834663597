@import "src/styles/styles";

.container {
    max-width: 414px;
    width: 100%;

    > span {
        font-size: 14px;
        font-weight: bold;
        color: $GREY_DARK;
    }
}
