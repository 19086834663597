@import "src/styles/styles";

.form {
    min-height: 60vh;

    hr {
        border: 1px solid $LIGHT_GREY;
        margin: 18px 0px 36px;
    }

    .full_input {
        max-width: unset;
        width: 100%;
        margin-bottom: 27px;
        > div {
            width: 100%;
            max-width: unset;
        }
    }

    .half_input {
        padding-top: 0px;
        margin-bottom: 27px;
        width: 49%;
        display: inline-block;
        vertical-align: top;
        > div {
            width: 100%;
            max-width: unset;
        }
    }
    .form_section {
        margin: 18px 0px 36px;
    }

    .first {
        margin-right: 2%;
    }
    .paragraph_input {
        //margin-bottom: 45px;
        outline: none;
        max-width: 100%;
        padding: 9px;
        box-sizing: border-box;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        border: 1px solid #cdcdcd;
        border-radius: 5px;
        min-height: 165px;
        width: 100%;
    }

    .third_input {
        vertical-align: top;
        width: 38%;
        margin-bottom: 0px !important;
        display: inline-block;
    }

    .add_button {
        display: block;
        margin-bottom: 27px;
    }

    .third_input_small {
        vertical-align: top;
        width: 20%;
        margin-bottom: 0px !important;
        display: inline-block;
    }
    .checkbox_container {
        margin-bottom: 36px;

        span {
            font-family: "Poppins", sans-serif;
        }

        label {
            width: 49%;
        }
    }
    label {
        color: $BLACK;
        font-size: 13px;
        transform: none;
        margin-bottom: 2px;
        font-family: "Poppins", sans-serif;
        font-weight: bold;
    }
}
