@import "src/styles/styles";

.table {
    background-color: transparent;
    font-family: "Poppins", sans-serif;

    :global(.MuiToolbar-root) {
        padding: 9px;

        :global(.MuiTablePagination-select) {
            min-width: auto;
        }
    }

    :global(.MuiSelect-select) {
        min-width: unset;
    }

    table {
        tbody {
            tr {
                font-family: "Poppins", sans-serif;

                // td {
                //   padding: 12px 18px;
                // }
            }
        }

        tfoot {
            td {
                border-bottom: none;
            }
        }
    }
}
