@import "src/styles/styles";

.affiliation_container {
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .affiliation_item {
        box-shadow: none;
        padding: 18px 0 !important;

        label {
            margin-right: 0px;
        }
    }
}

.form {
    min-height: 60vh;

    .form_footer {
        padding-bottom: 27px;
        box-sizing: border-box;
        border-bottom: 1px solid $BLUE_GREY;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        > div {
            width: 18px;
        }
    }

    .checkbox_container {
        margin-top: -27px;
        margin-bottom: 36px;

        span {
            font-family: "Poppins", sans-serif;
        }

        label {
            width: 49%;
        }
    }

    .full_input {
        position: relative;
        max-width: unset;
        width: 100%;
        margin-bottom: 27px;

        > div {
            width: 100%;
            max-width: unset;
        }
    }

    .half_input {
        max-width: unset;
        padding-top: 0px;
        margin-bottom: 27px;
        width: 49%;
        display: inline-block;
        vertical-align: top;

        > div {
            width: 100%;
            max-width: unset;
        }
    }

    .first {
        margin-right: 2%;
    }

    label {
        color: $BLACK;
        font-size: 13px;
        transform: none;
        margin-bottom: 2px;
        font-family: "Poppins", sans-serif;
        font-weight: bold;
    }

    .regions {
        margin-top: 27px;
    }
}

.csv_links {
    margin: 36px auto auto 9px;
    display: flex;
    flex-direction: row;
    :nth-child(2) {
        margin-left: 18px;
    }
}

.table_title {
    padding: 9px 9px 0px;
    box-sizing: border-box;
    margin-bottom: 0;
}

.export_button_base {
    cursor: pointer;
    color: $TURQUOISE;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: bold;

    &:hover {
        opacity: 0.6;
        transition: ease-in-out 125ms;
    }
}

.export_button_diabled {
    cursor: not-allowed;
}
.export_button_link {
    color: $BLACK;
    text-decoration: underline;
    font-weight: unset;

    &:hover {
        color: $TURQUOISE;
    }
}

.header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 999;
    padding-bottom: 18px;
    .back {
        width: fit-content;
        padding-top: 18px;
        cursor: pointer;
        color: $TURQUOISE;
        font-weight: bold;
    }
    .content {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .controls {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-end;
            > * {
                margin-left: 18px;
            }
        }
    }
    .title {
        color: $TURQUOISE;
        margin: 9px auto;
    }
    .filters {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 0 auto 9px 0;
        padding-bottom: 36px;
    }
    .bottom {
        border-bottom: 1px solid $BLUE_GREY;
        box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.09);
    }
}

.pill {
    min-width: 243px;
    flex-basis: 20%;
    margin: auto 18px 18px 0;
    display: flex;
    align-items: center;
    padding: 9px 18px;
    border: 1px solid $GREY_BORDER;
    justify-content: space-between;
    border-radius: 6px;
    .fav_label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 135px;
    }
    .delete {
        margin-left: 18px;
        cursor: pointer;
        > * {
            display: block;
        }
    }
}
.pill_select {
    border: 1px solid $NAVY;
}
.loading {
    cursor: not-allowed;
    color: $LIGHT_GREY;
}

.tooltip {
    background-color: $BLACK;
    color: $WHITE;
    border-radius: 6px;
    max-width: 360px;
    padding: 9px 18px;
    overflow-wrap: break-word;
}
.tip_link {
    color: $WHITE;
}

.supplier_categories {
    .main {
        margin-bottom: 9px;
        > span {
            font-weight: bold;
        }
        .sub {
            margin-left: 3px;
            > span::before {
                content: "● ";
            }
            .subsub::before {
                content: "○ ";
            }
            .subsub {
                margin-left: 3px;
                font-size: 0.8em;
            }
        }
    }
}
