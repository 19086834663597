@import "src/styles/styles";

.notes {
    width: 100%;
}
.dialog_btns {
    margin-top: 18px;
    display: flex;
    justify-content: flex-start;
    div {
        width: 9px;
    }
}
.dialog_checkbox {
    display: flex;
    align-items: center;
    > span {
        margin-left: 9px;
    }
}
.dialog_content {
    text-align: left;
}
