@import "src/styles/styles";

.container {
    font-size: 13px;
    width: 100%;
    max-width: $CONTENT_WIDTH;
    padding-right: 45px;
}
.with_tip {
    display: flex;
    align-items: center;
    > span {
        margin-right: 3px;
    }
}
.header {
    margin-bottom: unset;
}
