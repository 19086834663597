/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 29/06/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

.snackbar_container {
    position: fixed;
    top: 63px;
    right: 18px;
    margin-left: 18px;
    display: flex;
    flex-direction: column;
    z-index: 10000;
    pointer-events: none;

    > * {
        pointer-events: auto;
    }
}

