@import "src/styles/styles";

.dashboard {
    width: 100%;
    height: 100%;
    position: relative;

    .dashboard_window {
        flex: 1;
        background-color: $WHITE;
        position: relative;
        padding: 62px 18px;

        .dashboard_window_content {
            position: relative;
            height: 100%;
            width: 100%;
            max-width: $CONTAINER_WIDTH;
            margin: 0 auto;
        }

        .dashboard_window_content_fullwidth {
            max-width: 100%;
        }
    }
}
