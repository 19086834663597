@import "src/styles/styles";

.full_input {
    position: relative;
    max-width: unset;
    width: 100%;
    margin-bottom: 27px;
    > div {
        width: 100%;
        max-width: unset;
    }
}

.remove_cat_button {
    text-align: right;
    span {
        color: red;
    }
}

.plain_subindustry_row {
    //border: 1px solid black;
    margin-bottom: 0px;
    padding: 12px 6px;
    box-shadow: none;
}

.category_container {
    box-shadow: none;
    margin-bottom: 0px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .category_item {
        box-shadow: none;

        .category_label {
            label {
                margin-right: 0px;
            }
            span {
                font-weight: bold;
            }
        }
    }
}

.wrap_container {
    width: 100%;
}
.sub_container {
    display: flex;
    flex-direction: column;
}

.search {
    max-width: unset;
    width: 49%;
    .clear_btn {
        cursor: pointer;
    }
    .clear_btn_none {
        display: none;
    }
}
.industry_form {
    .category_name {
        font-size: 1.2em;
        font-weight: bold;
        margin-bottom: 9px;
        color: $TURQUOISE;
    }
}
