@import "src/styles/styles";

.overview {
    width: 100%;
}

.supplier {
    > div {
        min-width: 200px;
    }
}
.spacer {
    height: 9px;
}
