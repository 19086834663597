/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 20/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

.dialog {
    max-width: 396px;
    border-radius: 4px;
    margin: 0;
    width: 100%;

    .title {
        padding: 18px;
    }

    .content {
        padding: 9px 18px;

        > :not(:last-child) {
            margin-bottom: 9px;
        }
    }

    .buttonsRight {
        display: flex;
        flex: 1;
        justify-content: flex-end;

        > * {
            width: unset;
        }

        > :not(:first-child) {
            margin-left: 9px;
        }
    }

    .buttons {
        justify-content: space-between;
        padding: 9px 18px;
        background-color: #f5f7f9;
        box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.09);
        border-top: 1px solid #b4c3ca;

        > :not(:first-child) {
            margin-left: 9px;
        }
    }
}

.large {
    max-width: 882px;
}
