@import "src/styles/styles";

.label_tooltip_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
    margin-bottom: 0px !important;
    padding-top: 9px;
    box-sizing: border-box;
}

.error {
    color: $ERROR;
}

.text_area_container {
    outline: none;
    max-width: 100%;
    padding: 9px;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    min-height: 165px;
    width: 100%;
}

.full_text_input {
    margin-bottom: 27px;

    > div {
        > div {
            textarea {
                outline: none;
                max-width: 100%;
                padding: 9px;
                box-sizing: border-box;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                border-radius: 5px;
                min-height: 165px;
                width: 100%;
            }
        }
    }
}
